/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

body,
.dark,
.light {
  background-color: #fff !important;
}

h1,
.h1 {
  @apply text-7-5xl font-medium font-mono;
}
h2,
.h2 {
  @apply text-7xl font-medium font-mono;
}
h3,
.h3 {
  @apply text-4xl font-medium font-mono;
}
h4,
.h4 {
  @apply text-3xl font-bold font-mono;
}
h5,
.h5 {
  @apply text-2xl font-medium font-mono;
}
h6,
.h6 {
  @apply text-xl font-medium font-sans;
}

/* Firefox */
* {
  scrollbar-width: 2px;
  scrollbar-color: var(--fuse-white) var(--fuse-primary-500) !important;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 2px;
}

*::-webkit-scrollbar-track {
  background: #ffffff !important;
  border-radius: 0 !important;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--fuse-primary-500) !important;
}

.title-w-border {
  border-bottom: 4px solid var(--fuse-primary-500) !important;
}

blockquote {
  margin-left: 47px;
  @apply border-l-red-motorbest-600 pl-4;
  border-left-width: 3px;
  padding-bottom: 0 !important;
  margin-bottom: 48px;
  margin-top: 32px;

  color: #c00d0e;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;

  p {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }
}

.theme-default.light .mat-mdc-outlined-button.mat-primary {
  border-color: var(--fuse-primary-500) !important;
}

.widget-title {
  > span {
    color: rgb(30, 30, 30);
    position: relative;

    &::before {
      content: "";
      position: absolute;
      bottom: 6px;
      left: -36px;
      width: calc(100% + 36px);
      min-height: 2px;
      @apply bg-red-motorbest-600;
    }
  }
}

.widget-description {
  @apply text-lg font-normal leading-4xl font-sans text-gray-600;
}
